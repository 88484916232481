import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles(theme => (
  {
        msgChat : {
            marginBottom : '10px',
            width: '50%',
            padding : '15px'
        },
        inbound : {
          background: '#e5e1e1',
          float : 'left'
        },
        outbound :{
          background: '#a7e7a7',
          float : 'right'
        },
        msgContainer : {
            clear : 'both'
        },
        chatsContainer :{
            maxHeight: '550px',
            overflowY: 'scroll',
            overflowX:'hidden',
            padding : '20px'
        }
  }
))

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function DetalladoDialog(props) {
    const open = props.open;
    const handleClose = () => {
        props.onClose();
    };
    const classes = useStyles();

    return (
        <div>
            <Dialog fullWidth={true} maxWidth={'lg'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <Grid container >
                    <Grid item xs={6} style={{ borderRight: '1px solid #bababa'}} >
                        <DialogTitle id="customized-dialog-title" >
                            Agente : {props.rowData != '' && props.rowData.agentid}<br />
                            Puntaje : {props.rowData != '' && props.rowData.puntaje_numerico} - {props.rowData != '' && props.rowData.calificacion} <br />
                            Resumen : {props.rowData != '' && props.rowData.resumen}
                        </DialogTitle>
                        <DialogContent dividers>
                            {props.rowData != '' && props.rowData.analisis_completo.map(
                                (elem, index) => (
                                    <div key={index} style={{ marginBottom: '10px' }}>
                                        <Typography style={{ fontWeight: 'bold' }} gutterBottom>
                                            {elem.aspecto}
                                        </Typography>
                                        <Typography gutterBottom>
                                            {elem.descripcion}
                                        </Typography>
                                    </div>
                                )
                            )}
                        </DialogContent>
                    </Grid>
                    <Grid item xs={6} className={classes.chatsContainer} >
                        {props.currentChat && props.currentChat.map(
                            (chat) => (
                                <div className={classes.msgContainer} >
                                    <div className={clsx(classes.msgChat, {
                                        [classes.inbound]: chat.inbound,
                                        [classes.outbound]: !chat.inbound,
                                    })} >
                                        {chat.msg}
                                    </div>
                                   
                                </div>
                            )
                        )}
                    </Grid>
                </Grid>
             
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}