import React, {useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import SendIcon from "@material-ui/icons/Send";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { debounce } from "lodash";
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import ModalSendImage from './ModalSendImage';
import * as contactsActions from '../../../state/contacts/actions';
import * as authSelectors from '../../../state/auth/selectors';
import * as campaignsSelector from '../../../state/channels/selectors';
import AutoResponsesFileNew from './AutoResponsesFileNew';
import ChatOffline from './ChatOffline';

import { Input, IconButton, TextField, Button } from "@material-ui/core";
import ActionsButton from './ActionsButton';
import ModalMostrarUtms from './ModalMostrarUtms';




const SendBox = ( props ) => {

  const classes = props.classes;
  const campaigns = useSelector(campaignsSelector.getChannels());
  const [modalSendImageOpen, setModalSendImageOpen ] = useState(false)
  const [modalMostrarUtms, setModalMostrarUtms ] = useState(false)
  const [selectedImage, setSelectedImage ] = useState('');
  const textResponses = useSelector(campaignsSelector.getTextResponseByChannel(props.contact.campaignid));
  const mediaResponses = useSelector(campaignsSelector.getMediaResponseByChannel(props.contact.campaignid));
  const [outgoingMessage, setMessage] = useState('');
  const [linkParams, setLinkParams] = useState({});
  const [habilitadoMic, setHabilitadoMic] = useState(false);
  const [recording, setRecording] = useState(false);



  const dispatch = useDispatch();
  
  const handleModalImageClose = () => {
    setModalSendImageOpen(false)
    setSelectedImage('')
  }

  const handleSendUtms = (msg) => {
    setMessage(msg);
    sendMessage()
  }

  const handleNewFileCreated = () => {
    console.log('new file created')
  }

  const handleAdjuntoClose = () => {
    props.onPdfClose()
  }

  const handleAudioSend = (file) => {

    let msg = {
      contactId: props.contact.id,
      campaignid: props.contact.campaignid,
      callerid: props.contact.callerid,
      calledid: props.contact.calledid,
      message: {
        id: '0',
        from: me.username,
        msg: file.name,
        messagereference: file.messagereference,
        messagetype: 'audio',
        fechaCorta: moment().format('DD/MM/YYYY'),
        outbound: true,
        readByAgent: false,
        status: 0,
        isSecret: false,
        sent: false,
        sending: true
      }
    };
    dispatch(contactsActions.sendMessage(msg))
    setMessage('');
    handleAdjuntoClose()
  }

  const handlePdfSend = (file) => {

    let msg = {
      contactId: props.contact.id,
      campaignid: props.contact.campaignid,
      callerid: props.contact.callerid,
      calledid: props.contact.calledid,
      message: {
        id: '0',
        from: me.username,
        msg: file.name,
        messagereference: file.messagereference,
        messagetype:'file',
        fechaCorta: moment().format('DD/MM/YYYY'),
        outbound: true,
        readByAgent: false,
        status: 0,
        isSecret: false,
        sent : false,
        sending : true
      }
    };
    dispatch(contactsActions.sendMessage(msg))
    setMessage('');
    handleAdjuntoClose()
  }

  const handleModalImageSend = (commentText) => {
    let msg = {
      contactId: props.contact.id,
      campaignid: props.contact.campaignid,
      callerid: props.contact.callerid,
      calledid: props.contact.calledid,
      message: {
        id: '0',
        from: me.username,
        msg: commentText,
        messagereference: selectedImage,
        messagetype:'image',
        fechaCorta: moment().format('DD/MM/YYYY'),
        outbound: true,
        readByAgent: false,
        status: 0,
        isSecret: false,
        sent : false,
        sending : true
      }
    };
    dispatch(contactsActions.sendMessage(msg))
    setMessage('');
    handleModalImageClose()
  }

  const handleUtmClose = () => {
    setModalMostrarUtms(false)
    props.onCloseModalUtms();
  }

  const chekearUtms = () => {

    if(props.contact.utm_campaign && props.contact.utm_source && props.contact.utm_medium ){
      return true;
    }else{
      return false;
    }
  }




  const getSearchParams = (urlParam) => {
    try {
      var url = new URL(urlParam);
      let params = new URLSearchParams(url.search)

      let returnParams = {
        utm_source: params.get('utm_source'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign')
      };

      setLinkParams(returnParams)
    }
    catch (error) {
      console.error(error)
      return '';
    }

  }


  const replaceSearchParams = (urlParam, utms) => {
    var url = new URL(urlParam);
    Object.keys(utms).forEach((key) => {
      if (utms[key].length > 0) {
        url.searchParams.set(key, utms[key])
      } else {
        url.searchParams.delete(key)
      }

    });

    return url.toString();
  }

  const preSendMessage = () => {
    let tieneLink =  false;
    let tieneUtms = false;

    //Saltea si tiene utm_medium vtex y manda mensaje directamente
    if(outgoingMessage.includes("utm_medium=vtex")){
      sendMessage('')
      return;
    }

    if(outgoingMessage.includes("https://www.tevecompras.com") || outgoingMessage.includes("https://www.tevecompras.com")){
      tieneLink = true;
    }

    if(chekearUtms()){
      tieneUtms = true;
    }


    if(tieneLink && !tieneUtms){
      getSearchParams(outgoingMessage)
      setModalMostrarUtms(true)
      return;
    }

    sendMessage('','',{ utm_campaign : props.contact.utm_campaign ,
                    utm_medium : props.contact.utm_medium , 
                    utm_source : props.contact.utm_source 
    })
  }

  const sendMessage = (initialMessage , msgUtmsReplaced,utms) => {
    let message;
    if(initialMessage == true){
      message = props.contact.campaign_greetingMsg.replace("{{displayname}}", me.displayname);
    } else if (initialMessage == 'wait'){
      message = props.contact.waitMessage
    } else if (initialMessage == 'utmsReplace') {
      message = msgUtmsReplaced
    } else{
      message = outgoingMessage
    }
    let msg = {
      contactId: props.contact.id,
      campaignid: props.contact.campaignid,
      tipo : props.contact.campaignType,
      callerid: props.contact.callerid,
      calledid: props.contact.calledid,
      message: {
        id: '0',
        from: me.username,
        messagetype:'text',
        msg: message,
        fechaCorta: moment().format('DD/MM/YYYY'),
        outbound: true,
        readByAgent: false,
        status: 0,
        isSecret: false,
        sent : false,
        sending : true,
        fecha: moment().toISOString()
    }}
    dispatch(contactsActions.sendMessage(msg,utms))
    setMessage('');
  }

  
  const verify = useCallback(
    debounce(value => {

      //Short cut Textos
      if(value.startsWith("/") ){
        textResponses.find( message => {
          if(message.shortcut && value.startsWith("/"+ message.shortcut.toLowerCase() ) && message.shortcut != '' ){
            setMessage(message.message.replaceAll('{1}', props.contact.name ))
          }
        })
      }

      //Shortcut Imagenes
      if(value.startsWith("!") ){
        mediaResponses.find( message => {
          if(message.shortcut && value.startsWith("!"+ message.shortcut.toLowerCase() ) && message.shortcut != '' ){
            console.log(message.messagereference)
            setSelectedImage(message.messagereference)
            setModalSendImageOpen(true)
          }
        })
      }

     
      if(value.includes("https://tevecompras.com") ){
        console.log('tiene tevecompras')
      }
      
       
    }, 500),
    [textResponses,mediaResponses]
  );


  
  const handleMessageChange = event => {
    setMessage(event.target.value);
    verify(event.target.value);
  };

  useEffect(() => {
    if (props.linkUtms){
      setModalMostrarUtms(true)
    }
  }, [props.linkUtms]);

  useEffect(() => {
    setMessage(props.mensajePredef)
  }, [props.mensajePredef]);

  useEffect(() => {
    if(props.newMsg){
      setMessage(props.newMsg)
      props.onNewCreated();
    }
  }, [props.newMsg]);

  const me = useSelector(authSelectors.getMe(), shallowEqual);

  return (
    <>
        {/* <ActionsButton contacto={3} contact={props.contact} classes={classes} /> */}
        <ModalMostrarUtms message={outgoingMessage} dataPanel={props.dataPanel} linkParams={linkParams} username={me.username} open={modalMostrarUtms} contact={props.contact} onSendUtms={sendMessage}   onClose={handleUtmClose}   />
        <AutoResponsesFileNew contactId={props.contact.id} onNewFile={handlePdfSend}  isOpen={props.newPdf} onClose={handleAdjuntoClose}   />
        <ModalSendImage selectedImageIn={selectedImage}  open={modalSendImageOpen} onClose={handleModalImageClose} onSend={handleModalImageSend} />
        {!props.tieneSalientes && props.contact.campaign_automaticGreeting  ? <IconButton className={classes.sendButton} 
            onClick={(e) => { sendMessage(true) }} 
          >
            <EmojiPeopleIcon className={classes.sendIcon} />
          </IconButton> : null }
        <IconButton className={classes.sendButton}
          onClick={(e) => { sendMessage('wait') }}
        >
          <HourglassEmptyIcon className={classes.sendIcon} />
        </IconButton>
       
        { props.contact.campaignid !== 13 ? <>
        <div style={{ width: '100%' }}>
          <div style={{ height: '80px' }}  >
            <TextField
              id="reply-msg"
              className={classes.rootSendBox}
              // autoComplete
              placeholder="Escribir mensaje"
              inputProps={props.contact.textLength ? { maxLength: props.contact.textLength } : {}}
              value={outgoingMessage}
              onChange={(e) => { handleMessageChange(e) }}
              onKeyPress={event => event.key === 'Enter' && outgoingMessage ? sendMessage() : null}
              multiline
              fullWidth
              lang='es'
              spellCheck="true"
              rows={3}
              variant="outlined"
            />
          </div>
          <div style={{ height: '40px' }} > 
            <ActionsButton onSendMessage={sendMessage} onAudioSend={handleAudioSend}  recording={recording} onOpenResponses={props.onOpenResponses} onNewMsg={props.onNewMsg} onPdfFile={props.onPdfFile} onSendTevecomprasLink={props.onSendTevecomprasLink} dataPanel={props.dataPanel} messageDue={props.messageDue} campaignid={props.contact.campaignid} contact={props.contact} classes={props.classes} outgoingMessage={outgoingMessage} ></ActionsButton> 
          </div>
        </div>

        </> : <ChatOffline contact={props.contact} />}
      </>
  )
}

  export default SendBox;