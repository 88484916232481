import React, { useEffect, useState, useCallback } from "react";
import { Select, InputLabel, Grid, Tooltip,withStyles, MenuItem, IconButton, Button, InputAdornment, MenuList, TableBody, TableCell, TableHead, TableRow, Chip, Avatar, Typography, Box, TextField } from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';
import Api from "../../state/Api";
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { debounce } from "lodash";

import AddIcon from '@material-ui/icons/Add';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { Refresh } from "@material-ui/icons";
import * as campaignsSelector from '../../state/channels/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
    LocalizationProvider,
    DateTimePicker,
    DatePicker
} from '@material-ui/pickers';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import DetalladoDialog from "./DetalladoDialog";
import BlockUi from 'react-block-ui';
import MemoryIcon from '@material-ui/icons/Memory';
import * as contactsActions from '../../state/contacts/actions';
import * as authSelectors from '../../state/auth/selectors';

const useStyles = makeStyles((theme) => ({

    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        '& .super-app-theme--header': {
            color: 'black'
        },
        '& .super-app-theme--row': {
            color: 'black'
        },
    },
    blackText: {
        color: 'black'
    },
    tableContainer: {
        height: 'calc(100vh - 175px)'
    },
    chip: {
        marginBottom: '10px'
    },
    paperStyle: {
        width: '50%',
        textAlign: 'center',
        marginBottom: '10px'
    },
    destacadoNumber: {
        color: '#42a5f5'
    },
    heightAdjust: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 185px)",
        // border: theme.palette.borders
    },
    th: {
        color: 'red'
    },
    topPadding : {
        padding : '20px'
    }
}));


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


function ReportesAI() {
    const [clientes, setClientes] = React.useState([]);
    const classes = useStyles();
    const [pageSize, setPageSize] = React.useState(50);
    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState();
    const [loading, setLoading] = React.useState();
    const [selectedCampaign, setSelectedCampaign] = useState(0);
    const campaigns = useSelector(campaignsSelector.getChannels());
    const [fechaDesde, setFechaDesde] = useState(new Date());
    const [fechaHasta, setFechaHasta] = useState(new Date());
    const [selectedAgente, setSelectedAgente] = useState('');    
    const [resultados,setResultados] = useState([]);
    const [agentes,setAgentes] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [blockScreen, setBlockScreen] = useState(false);
    const [rowData, setRowData] = useState('');
    const [currentChat, setCurrentChat] = useState('');
    const dispatch = useDispatch();
    const me = useSelector(authSelectors.getMe());

    const cambioCampaign = (campaignId) =>{
        Api.req.get(`/campaigns/${campaignId}/agents`)
        .then((response) => {
          setAgentes(response.data.data)
          setSelectedAgente('')
        });
     
    }

    const handleOpenModal =  async (data) => {
        let dataCopy = {...data};
        const chatRecords = await Api.req.get(`/prospectos/agent/${me.username}/prospecto/${data.idprospecto}/${data.campaignid}/interactions`, { status: 1 });
        console.log(chatRecords);
        let filterSecret = chatRecords.data.messages.filter( (chat) => chat.isSecret == false )
        setCurrentChat(filterSecret)

        setRowData(data)
        setModalOpen(true)
    }


    const handleCloseModal = () => {
        setRowData('')
        setModalOpen(false)
    }

    const formatData =  (data) =>{
        return data.map((registro) => Object.assign( {id : Number(registro.i)}, registro)  )
    }

    const buscar = (param) => {
        setBlockScreen(true)
       
        let fechaDesdeFormateado = moment(fechaDesde).format('YYYYMMDD');
        let fechaHastaFormateado = moment(fechaHasta).format('YYYYMMDD'); 
        Api.req.get(`/supervisor/stats/ai/campaign/${selectedCampaign}/byDate?startDate=${fechaDesdeFormateado}&endDate=${fechaHastaFormateado}&agentid=${selectedAgente}`)
            .then((response) => {
                setBlockScreen(false)
                setClientes( formatData(response.data.data))
                //setRowCount(response.data.total)
            })

    }

    const replaceResultados = ( id, response) => {
        let clientesCopy = [...clientes];
        let foundIndex = clientesCopy.findIndex((elem) => elem.id == id );
        clientesCopy[foundIndex].calificacion = response.calificacion;
        clientesCopy[foundIndex].resumen = response.resumen;
        clientesCopy[foundIndex].puntaje_numerico = response.puntaje_numerico;
        clientesCopy[foundIndex].analisis_completo = response.analisis_completo;
        setClientes(clientesCopy)
    }

    const handleAnalisisRapido = (pregunta, respuesta,id, idProspecto) => {
        setBlockScreen(true)
        let params = {
            cliente: pregunta ,
            respuesta: respuesta
        };
        Api.req.post(`https://omnidev.tevecompras.com/ai/analyst/` + idProspecto , params)
        .then((response) => {
            console.log(response)
            setBlockScreen(false)
            replaceResultados( id, response.data)
            //setRowCount(response.data.total)
        })
    }


    const columns = [{
        headerName: 'Fecha',
        headerClassName: 'super-app-theme--header',
        field: 'fecha',
        headerAlign: 'center',
        width: 120,
        valueFormatter: (params) => {
            return `${moment(params.value).format('DD/MM/YYYY')}`;
        }
    }, {
        field: 'callerid',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        width: 140,
    }, {
        field: 'agentid',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        width: 130,
    }, {
        field: 'pregunta',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params) => (
            <div style={{ height: '100%', whiteSpace: 'break-spaces' }}>
                <Typography color="textSecondary">{params.row.pregunta}</Typography>
            </div>
        )
    },/* {
        field: 'respuesta',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params) => (
            <HtmlTooltip title={params.row.respuesta} placement="right" arrow>  
                <div style={{ height : '100%', whiteSpace : 'break-spaces' }}>
                        <Typography color="textSecondary">{params.row.respuesta}</Typography>
                </div>
            </HtmlTooltip>
        )
    }*/, {
        field: 'calificacion',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        width: 140,
    }, {
        field: 'resumen',
        headerClassName: 'super-app-theme--header',
        headerName: 'Resumen',
        headerAlign: 'center',
        width: 240,
        renderCell: (params) => (
            <div style={{ height: '100%', whiteSpace: 'break-spaces' }}>
                <Tooltip title={params.row.resumen} >  
                    <Typography color="textSecondary">{params.row.resumen}</Typography>
                </Tooltip>
                
            </div>
        )},
        {
        field: 'Acciones',
        headerClassName: 'super-app-theme--header',
        headerName: 'Acciones',
        headerAlign: 'center',
        width: 200,
        renderCell: (params) => (<div style={{ lineHeight: '44px' }}> <IconButton onClick={(event) => { handleAnalisisRapido(params.row.pregunta, params.row.respuesta, params.row.id, params.row.idprospecto) }} size="small" variant="contained" color="primary"> <MemoryIcon /> </IconButton> {params.row.analisis_completo && <Button style={{ marginLeft: '15px' }} onClick={(event) => { handleOpenModal(params.row ) }} size="small" variant="contained" color="secondary"> Detallado</Button>}</div>)
    }]

    return (
        <BlockUi blocking={blockScreen}>
         <Box style={{ height: 'calc(100vh - 65px)' , display : 'flex', flexDirection : 'column'}} >
          
            <DetalladoDialog rowData={rowData} open={modalOpen} currentChat={currentChat} onClose={handleCloseModal} />
            <Grid container >
                <Grid item xs={8} >
                    <Grid container  >
                        <Grid item xs={4} className={classes.topPadding}>
                                <InputLabel id="demo-customized-select-label">Campaña</InputLabel>
                                <Select
                                    labelId="diposition-label"
                                    id="diposition"
                                    value={selectedCampaign}
                                    onChange={(event) => { setSelectedCampaign(event.target.value);cambioCampaign(event.target.value) }}
                                    fullWidth={true}
                                >
                                    {
                                        campaigns.length > 0 ? campaigns.map((option, index) =>
                                            <MenuItem key={index} value={option.id}>{option.Nombre}</MenuItem>
                                        ) : null}
                                    <MenuItem value={0}>Seleccione</MenuItem>
                                </Select>
                            
                        </Grid>
                        <Grid item xs={2} className={classes.topPadding}>
                            <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter} >
                                <DatePicker
                                    disableToolbar
                                    renderInput={(props) => <TextField {...props} helperText="" />}
                                    variant="inline"
                                    fullWidth={true}
                                    size="small"
                                    inputFormat="DD/MM/YYYY"
                                    margin="normal"
                                    id="date-picker-inline"
                                    className={classes.cien}
                                    label="Fecha Desde"
                                    autoOk={true}
                                    value={fechaDesde}
                                    onChange={date => setFechaDesde(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} className={classes.topPadding}>
                            <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                                <DatePicker
                                    disableToolbar
                                    renderInput={(props) => <TextField {...props} helperText="" />}
                                    variant="inline"
                                    inputFormat="DD/MM/YYYY"
                                    margin="normal"
                                    size="small"
                                    id="date-picker-inline2"
                                    className={classes.cien}
                                    label="Fecha Hasta"
                                    fullWidth={true}
                                    autoOk={true}
                                    value={fechaHasta}
                                    onChange={date => setFechaHasta(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>  
                        <Grid item xs={2} className={classes.topPadding} >
                            <InputLabel id="demo-customized-select-label">Agente</InputLabel>
                            <Select
                                labelId="diposition-label"
                                id="diposition"
                                fullWidth={true}
                                value={selectedAgente}
                                onChange={(event) => setSelectedAgente(event.target.value) }
                            >
                            {
                            agentes.length > 0 ? agentes.map((option, index) => 
                                <MenuItem key={index} value={option.Agent}>{option.Agent}</MenuItem>
                            ) : null }
                            <MenuItem value=''>Seleccione</MenuItem>    
                            </Select>
                        </Grid>
                        <Grid item xs={2} className={classes.topPadding}>
                            <Button startIcon={<SearchIcon />} variant="contained" onClick={() => buscar()} style={{ float: 'left', marginRight: '10px' }} color="primary" > Buscar </Button>
                        </Grid>  
                    </Grid>  
                </Grid>
            </Grid>
            <div style={{ marginBottom: '20px', textAlign: 'right' }}>
            </div>
            <DataGrid className={classes.root} style={{ backgroundColor: '#fff' }}
                rows={clientes}
                rowHeight={180}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={rowCount}
                loading={loading}
                getRowClassName={(params) =>
                    `super-app-theme--row`
                }
                
                pagination
                density="compact"
                
                onPageChange={(newPage) => setPage(newPage)}
                {...clientes}
            />

            </Box>
        </BlockUi>
    )
}

export default ReportesAI;