import React, { useState }  from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { MessageContent } from './MessageContent';
import { IconButton,Button,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle,  Avatar, ListItemText, Tooltip } from "@material-ui/core";
import Bubble from './Bubble'; 
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Modal from '@material-ui/core/Modal';
// import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ExpandMore from '@material-ui/icons/ExpandMore';
import unified from 'unified'
import parse from 'remark-parse'
import remark2react from 'remark-react'
import emoji from 'remark-emoji'
import externalLinks from 'remark-external-links'
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AudioPlayer from 'material-ui-audio-player';
import MenuList from '@material-ui/core/MenuList';
import Api from '../../../state/Api';
import * as contactsActions from '../../../state/contacts/actions';
import * as appActions from '../../../state/app/actions';
import ClearIcon from '@material-ui/icons/Clear';
import LaunchIcon from '@material-ui/icons/Launch';
import Zoom from '@material-ui/core/Zoom';
import PublishIcon from '@material-ui/icons/Publish';
// import { Editor, EditorState, RichUtils } from "draft-js";
import clsx from 'clsx';
// import './imgContainer.css';
import PreSaveDialog from './PreSaveDialog';
import SecureImage from '../../shared/SecureImage';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import * as authSelectors from '../../../state/auth/selectors';

const useStyles = makeStyles(theme => ({
  paper: {
    width : 'auto',
    height : 'auto',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    maxHeight : '100%',
    // maxWidth : '100%',
    position: 'absolute',
    textAlign : 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1
  },
  referal : {
    background: '#e5e5e5',
    borderLeft: '3px solid #52bc43'
  },
  referalContainer : {
    paddingBottom : 0
  },
  audioContainer : {
    marginBottom: '18px'
  },
  closeBtn:{
    position : 'absolute',
    top : '20px'
  },
  promptColor: {
    backgroundColor: '#d2d7fa'
  },
  chatItemPrimaryText:{
    fontSize:'1em',
    color: '#222',
    wordBreak: 'break-word'
    //whiteSpace: 'pre-wrap'
    // lineHeight: '1.2',
    // paddingTop: '0px',
    // paddingBottom: '0px'
  },
  chatItemSecondaryText:{
    fontSize:'0.7em',
    color: '#999',
    flexDirection: 'right'
  },
  imgContainerModal:{
    display: 'flex',
    justifyContent: 'center',
    alignIitems: 'center',
    maxWidth : '100%',
    alignItems: 'center',
    flex: 'auto',
  },
  modalImg:{
    display: 'flex',
    maxWidth : '100%'
  },
  contenedor: {
    maxWidth: '330px'
  }, 
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
    zIndex: 1,
    maxWidth: '330px',
    maxHeight: '330px',
    overflow: 'hidden',
    cursor : 'pointer'
  },
  imgContainerSvg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
    zIndex: 1,
    maxWidth: '50px',
    maxHeight: '500px',
    overflow: 'hidden'
  },
  imgSrc: {
    maxWidth : '100%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  videoSrc: {
    maxWidth : '60%',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

const Message = ( props ) => {
  const classes = useStyles(useTheme())
  const [openModal, setOpenModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [hoverMenu, setHoverMenu] = useState(false);
  const [hoverBubble, setHoverBubble] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElEmoji, setAnchorElEmoji] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const anchorRef = React.useRef(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openPreSave, setOpenPreSave] = React.useState(false);
  const [openPopOver, setOpenPopOver] = React.useState(false);
  const dispatch = useDispatch();
  const spanRef = React.useRef();
  let bgColor = props => (props.outgoing ? props.isSecret ? '#edf8c6' : '#dcf8c6' : '#fff')



  const mas24horas = moment().diff(props.time, 'hours') >= 24 ? true : false;

  const handleClose = () => {
    setOpenModal(false);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleClickEmoji = (event) => {
    console.log('hadnle click emoji')
    setAnchorElEmoji(spanRef.current)
  }

  const openEmoji = Boolean(anchorElEmoji);
  const id = openEmoji ? 'simple-popover' : undefined;

  const handleClickOpenPreSave = () => {
    setOpenPreSave(true);
  };

  const handleClosePreSave = () => {
    setOpenPreSave(false);
  };


  const stringToColor = (string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  const handleClick = () => {
    setOpenModal(true);
  }

  const downloadAs = (url, name) => {
    axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream"
      },
      responseType: "blob"
    })
      .then(response => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch(err => {
        console.log("error", err);
      });
  };

  const downloadImage = () => {
    downloadAs(props.messagereference,'outputFile.png')
  }

  const downloadFile = () => {
    console.log(props)
    let name = props.attachmentName ? props.attachmentName : props.message;
    downloadAs(props.messagereference, name)
  }

  const PreSaveDocuCliente = () => {
  }

  const saveDocuClienteEspecifico = async (extraData) => {
 
      const sendData = {
        nrodocu: extraData.nroDocu  ,
        messagereference: props.messagereference,
        messagetype: props.messagetype,
        isStaff: props.contact.isStaff,
        tipo : extraData.tipo,
        verificado: extraData.verificado
      }

      dispatch(appActions.addToast('Guardando documentación ...', 'warning'))
      try {
        await Api.req.patch(`/prospectos/contact/${props.contactId}/interaction/${props.messageId}`, sendData)
        dispatch(appActions.addToast('Se ha guardado la documentación'))
        setOpenPreSave(false)
      } catch (err) {
        dispatch(appActions.addToast('Ha habido un error al borrar el mensaje :' + err, 'error'))
      }
    

  }

  const saveDocuCliente = async ()  => {
    
    const sendData = {
      nrodocu: props.contact.Nro_Docu,
      messagereference: props.messagereference,
      isStaff: false
    }
    dispatch(appActions.addToast('Guardando documentación ...','warning'))
    try{
      await Api.req.patch(`/prospectos/contact/${props.contactId}/interaction/${props.messageId}`, sendData)
      dispatch(appActions.addToast('Se ha guardado la documentación'))
    }catch(err){
      dispatch(appActions.addToast('Ha habido un error al borrar el mensaje :' + err ,'error'))
    }


  }

  const downloadOrigFile = () => {
    downloadAs(props.OriginMessageAttachment,props.attachmentName)
  }

  const downloadVideo = () => {
    downloadAs(props.messagereference,'outputFile.mp4')
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const cargarDatos = () => {
    console.log('cargar datos');
    let datos = {
      "campaignid": String(props.contact.campaignid),
      "name": props.messageObject.adoData.Nombres,
      "surname": props.messageObject.adoData.Apellido,
      "Tipo_Docu": props.messageObject.adoData.tipo_docu,
      "Nro_Docu": props.messageObject.adoData.nro_docu,
      "callerid": props.contact.callerid
    };
    
    Api.req.put(`/prospectos/agent/${props.agentId}/prospecto/${props.contact.id}/profile`,datos)
    .then((response) => {
        //setGuardando(false)
        dispatch(appActions.addToast('Contacto Modificado'))
        datos.contactid = props.contactId;
        dispatch(contactsActions.updateContactDatos(datos)).then(
          () => props.handleGuardoDatos(datos)
        )
        //props.handleGuardoDatos(datos)
    })
    .catch(error => {
        //setGuardando(false)
        dispatch(appActions.addToast('Ha habido un error','error'))
        console.log('Ha habido un error')
    })


  }

  const me = useSelector(authSelectors.getMe(), shallowEqual);

  const handleSendEmoji = async (unicode) => {
    let emojiMsgObject = {
      "contactId": props.contact.id,
      "campaignid": props.campaignId,
      "callerid": props.contact.callerid,
      "calledid": props.calledid,
          "message": {
            "id": props.messageObject.messageid,
            "from": me.username,
            "messagetype": "reaction",
            "msg": unicode,
            "fechaCorta": moment().format("DD/MM/YYYY"),
            "outbound": true,
            "readByAgent": false,
            "status": 0,
            "isSecret": false,
            "sent": false,
            "sending": true,
            "fecha": new Date()
        }
    }
    setAnchorElEmoji(null);
    dispatch(contactsActions.sendMessageReaction(emojiMsgObject))

  }

  const getText = (text) => {
    return unified()
      .use(parse)
      .use(emoji, {
        padSpaceAfter: true, // defaults to false
        emoticon: false // defaults to false
      })
      .use(externalLinks, { target: '_blank', rel: ['nofollow'] })
      .use(remark2react, {
        remarkReactComponents: {
          p: 'div'
        }
      })
      .processSync(text).result
  }

  const createMarkup = (text) => {

    let textReplaced = text != null ? text.replace(/\*([^*]+)\*/g, "**$1**") : '';

    if(props.messagetype === 'image'){
      let img = <div className={classes.contenedor} role="button"><div className={classes.imgContainer} onClick={handleClick}> <SecureImage className={classes.imgSrc} campaignid={props.campaignId} src={props.messagereference} /> </div><div style={{ marginTop: '10px' }}> {getText(textReplaced) }</div> 
        {props.messageObject.isMarketing ? <a target="_blank" href={props.messageObject.button1}><Button fullWidth size="small" variant="outlined" style={{ marginBottom: '10px' }} startIcon={<LaunchIcon />} color="primary">{props.messageObject.button1Name}</Button></a> : ''  } 
      </div>;
      return  img;
    }

    if (props.messagetype === 'sticker') {
      let img = <div className={classes.contenedor} role="button"><div className={classes.imgContainer} onClick={handleClick}> <SecureImage className={classes.imgSrc} campaignid={props.campaignId} src={props.messagereference} /> </div><div style={{ marginTop: '10px' }}>{getText(textReplaced)}</div>
        {props.messageObject.isMarketing ? <a target="_blank" href={props.messageObject.button1}><Button fullWidth size="small" variant="outlined" style={{ marginBottom: '10px' }} startIcon={<LaunchIcon />} color="primary">{props.messageObject.button1Name}</Button> </a> : ''}
      </div>;
      return img;
    }
    if(props.messagetype === 'story'){
      let img = <div onClick={() => openInNewTab(props.messagereference)} className={classes.contenedor} role="button"><div className={classes.imgContainer}><video className={classes.videoSrc} src={props.messagereference} alt="" /></div><div style={{ marginTop: '10px' }}>{getText(textReplaced)}</div></div> 
      return  img;
    }
    if (props.messagetype === 'referral') {
      let img = <div onClick={() => openInNewTab(props.messageObject.originAttachmentURL)} className={classes.contenedor} role="button"><div className={classes.messageReference}><img className={classes.imgSrc} campaignid={props.campaignId} src={props.messagereference} /></div><div style={{ marginTop: '10px' }}>{getText(textReplaced)}</div></div>
      return img;
    }

    if(props.message && props.message.includes("data:image")){
      let img = <div className={classes.contenedor} role="button"><div className={classes.imgContainerSvg}><SecureImage className={classes.imgSrc} campaignid={props.campaignId} src={props.messagereference} /></div></div> 
      return  img;
    }
    if(props.messagetype === 'audio'){
     return <div className={classes.audioContainer}> <AudioPlayer
        elevation={0}
        width="300px"
        style={{'marginBottom':'18px'}}
        variation="default"
        spacing={0}
        volume={false}
        // download={true}
        // loop={loop}
        // spacing={spacing}
        // debug={false}
        src={props.messagereference}
      /></div>
    }

    if (props.messagetype === 'file' || props.messagetype === 'document'){
      return  <>
                 {props.message}
                <IconButton onClick={() => downloadFile()}  style={{ color: '#2b2929'}} aria-label="delete" className={classes.margin}>
                  <GetAppIcon fontSize="large" />
                </IconButton>
              </>
     }

     if(props.messagetype === 'video'){
      return  <IconButton onClick={() => downloadVideo()}  style={{ color: '#2b2929'}} aria-label="delete" className={classes.margin}>
                <GetAppIcon fontSize="large" />
              </IconButton>
     }

    if (props.messagetype === 'prompt') {
      let content = <div className={classes.contenedor} role="button">
        <div >  {props.message}</div>
        <Button onClick={cargarDatos} fullWidth size="small" variant="outlined" style={{ marginBottom: '10px' }} startIcon={<PublishIcon />} color="primary"> {props.messageObject.button1Name} </Button>
      </div>;
      return content;
    }

    return unified()
              .use(parse)
              .use(emoji, {
                padSpaceAfter: true, // defaults to false
                emoticon: false // defaults to false
              })
              .use(externalLinks, {target: '_blank', rel: ['nofollow']})
              .use(remark2react, {
                remarkReactComponents: {
                  p: 'div'
                }
              })
              .processSync(textReplaced).result
        // + '</div>'
  }

  const body = (
    <Dialog fullScreen open={openModal} onClose={handleClose} fullWidth>
        <div className={classes.closeBtn} >
          <IconButton onClick={() => handleClose()} aria-label="delete" className={classes.margin}>
            <HighlightOffIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={() => downloadImage()}  aria-label="delete" className={classes.margin}>
            <GetAppIcon fontSize="large" />
          </IconButton>
        </div>
        <div className={classes.imgContainerModal} >
          <img className={classes.modalImg} src={props.messagereference} alt=""></img>   
        </div>   
    </Dialog>
  );

  const textBody = text => {
    return <div>{ text }</div>
  }


  const getCalledId = () => {
    if (props.inbound && props.contact.source != 'mercadolibre'){
      return (<div style={{ float: 'right', paddingTop: '3px', marginLeft: '5px' }} >{props.calledid}</div>)
    }
    
  }

  const renderSecondary = () =>{
    
    if(props.readBy && !props.outbound){
      return (
        <>
          {props.reacted ? <Zoom in={props.reacted}><span style={{ position: 'absolute', fontSize: '16px', marginLeft: 0, bottom: -6, rotate: '-10deg' }}>{props.attachmentName}</span></Zoom> : ''}  {moment(props.time).calendar()}  <Tooltip title={props.readBy} placement="right"><CheckIcon style={{ width: '0.8em', height: '0.8em', marginLeft: 10, marginBottom: -5 }} /></Tooltip> { getCalledId() }
        </>
      )
    }else if(props.hasError){
      return (
        <>
          {moment(props.time).calendar()} <Tooltip title='Error' placement="right"><ClearIcon style={{ color: 'red', width: '0.8em', height: '0.8em', marginLeft: 10, marginBottom: -5 }} /></Tooltip><span style={{ color: 'red' }}> {props.errorMessage}</span>{getCalledId()}
        </>
      )
    }else if(props.read){
      return (
        <>
          {props.reacted ? <Zoom in={props.reacted}><span style={{ position: 'absolute', fontSize: '16px', marginLeft: 0, bottom: -6, rotate: '-10deg' }}>{props.attachmentName}</span></Zoom> : ''} {moment(props.time).calendar()} <Tooltip title='Leido' placement="right"><DoneAllIcon style={{ width: '0.8em', color: props.read && "#0077cc", height: '0.8em', marginLeft: 10, marginBottom: -5 }} /></Tooltip> {getCalledId()}
        </>
      )
    }else if(props.delivered){
      return (
        <>
          {props.reacted ? <Zoom in={props.reacted}><span style={{ position: 'absolute', fontSize: '16px', marginLeft: 0, bottom: -6, rotate: '-10deg' }}>{props.attachmentName}</span></Zoom> : ''}  {moment(props.time).calendar()} <Tooltip title='Entregado' placement="right"><DoneAllIcon style={{ width: '0.8em', height: '0.8em', marginLeft: 10, marginBottom: -5 }} /></Tooltip> {getCalledId()}
        </>
      )
    }else if(!props.sent && props.sending){
      return(
        <>
          {moment(props.time).calendar()} <QueryBuilderIcon style={{ width: '0.8em', height: '0.8em', marginLeft: 10, marginBottom: -5 }} /> {getCalledId()}
        </>
      )
    }else if(props.sent){
      return(
        <>
          {moment(props.time).calendar()} <Tooltip title='Enviado' placement="right"><CheckIcon style={{ width: '0.8em', height: '0.8em', marginLeft: 10, marginBottom: -5 }} /></Tooltip> {getCalledId()}
        </>
      )
    }else if(props.isSecret){
      return(
        <>
          { 'por ' + props.author + ' | ' + moment(props.time).calendar() }
        </>
      )
    }else if(props.reacted){
      console.log(props.message,'pasa x este')
      
      return (
        <>
        { moment(props.time).calendar() } {props.reacted ? <span style={{position: 'absolute', fontSize: '16px', marginLeft: -95, bottom: 25, rotate: '-10deg' }}>{props.attachmentName}</span>: ''} <Tooltip title='Leido' placement="right"><DoneAllIcon style={{width: '0.8em', color : props.read && "#0077cc" ,height: '0.8em', marginLeft: 10, marginBottom: -5 }} /></Tooltip>
        </>
      )      
    }else{
      return(
        <>
          {moment(props.time).calendar()} {getCalledId()}
        </>
      )
    }

  }
  
  const handleCloseEmojiPop = () => {
    setAnchorElEmoji(null);
  }

  const handleToggle = (event) => {
    if(!hoverMenu){
      setOpenMenu(!openMenu)
      setAnchorEl(event.currentTarget);
    }
    
  };

  const handleLeavePopper = (event) => {
    setOpenMenu(false)
    setHoverMenu(false)
    setAnchorEl(event.currentTarget);
  };
  

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };



  const handleEliminarMsg =  async () => {
    console.log('pasa x eliminar msg')

    try {
      await Api.req.delete(`/prospectos/contact/${props.contactId}/interaction/${props.messageId}`)
      .then((response) => { 
        let contacto = {
          id : props.contactId,
          campaignid : props.campaignId
        } 
        dispatch(contactsActions.fetchMessages(contacto, props.agentId));
        setOpenDialog(false);
      });
    }
    catch(err){
      dispatch(appActions.addToast('Ha habido un error al borrar el mensaje :' + err,'error'))
    }
  }

  return (
  <MessageContent style={{  maxWidth: '80%' }}
    outgoing={!!props.outbound }
    isSecret={props.isSecret}
  >
      <PreSaveDialog open={openPreSave} nroDocu={props.contact.Nro_Docu} isStaff={props.contact.isStaff} onSave={saveDocuClienteEspecifico} onClose={() => setOpenPreSave(false)} />
   <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">Eliminar Mensaje</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Estas a punto de eliminar un mensaje {props.username}. ¿ Estás seguro que deseas hacerlo ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancelar
        </Button>
         <Button onClick={handleEliminarMsg} color="primary" autoFocus>
          
          Eliminar Mensaje
        </Button> 
      </DialogActions>
    </Dialog>
    <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    {!! props.message && props.message.delivered }
    {!!props.outbound && props.author && !props.isSecret && <Tooltip title={props.author}><Avatar style={{ backgroundColor: stringToColor(props.author) }}><div style={{color: 'rgba(255, 255, 255)', mixBlendMode: 'none'}}>{props.author[0]}</div></Avatar></Tooltip>}
    <Tooltip style={{display : 'none'}} arrow title={props.inbound ? <InsertEmoticonIcon style={{cursor : 'pointer'}} onClick={handleClickEmoji}  /> : ''} placement="right" interactive> 
    <Bubble 
      outgoing={!!props.outbound}
      ref={spanRef}
      isSecret={props.isSecret}
      style={{display:'flex', flexDirection: 'row'}}
      className={clsx( {
        [classes.promptColor]: props.messageObject.isPrompt, 
        [classes.referal]: props.messageObject.messagetype == "referral"
        })}
    >
          <Popover
            id={id}
            open={openEmoji}
            anchorEl={anchorElEmoji}
            onClose={handleCloseEmojiPop}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            style={{fontSize : '10px'}}
          >
            <IconButton size="small" style={{color: 'inherit'}} onClick={() => handleSendEmoji("\uD83D\uDC4D")}>👍</IconButton>
            <IconButton size="small" style={{ color: 'inherit' }} onClick={() => handleSendEmoji("\uD83D\uDC97")}>❤️</IconButton>
            <IconButton size="small" style={{ color: 'inherit' }} onClick={() => handleSendEmoji("\uD83D\uDE02")}>😂</IconButton>
            <IconButton size="small" style={{ color: 'inherit' }} onClick={() => handleSendEmoji("\uD83D\uDE2E")}>😮</IconButton>
            <IconButton size="small" style={{ color: 'inherit' }} onClick={() => handleSendEmoji("\uD83D\uDE25")}>😢</IconButton>
            <IconButton size="small" style={{ color: 'inherit' }} onClick={() => handleSendEmoji("\uD83D\uDE4F")}>🙏</IconButton>
          </Popover>
      {props.isSecret && !props.messageObject.isPrompt && <AnnouncementIcon style={{fontSize: '2.8em', marginRight: 10, marginTop: 4, color: '#999' }} />}
      {props.messageObject.isPrompt && <PermContactCalendarIcon style={{fontSize: '2.8em', marginRight: 10, marginTop: 4, color: '#999' }} />}
      <Tooltip title={moment(props.time).format('DD/MM/YYYY HH:mm')}>
        {/* <div dangerouslySetInnerHTML={createMarkup(props.message)} /> */}
        <ListItemText 
          classes={{ primary: classes.chatItemPrimaryText, secondary: classes.chatItemSecondaryText }}
          primary={ createMarkup(props.message) }  
          style={{ whiteSpace: 'pre-wrap'}}
          // primary=          {
          //   unified()
          //     .use(parse)
          //     .use(emoji, {
          //       padSpaceAfter: true, // defaults to false
          //       emoticon: true // defaults to false
          //     })
          //     .use(remark2react)
          //     .processSync(props.message).result
          // }
          secondary={renderSecondary()}
        />
        
        {/* <div className="editorContainer">     
          <div className="editors">
            <Editor
              // editorState={this.state.editorState}
              // handleKeyCommand={this.handleKeyCommand}
              // onChange={this.onChange}
            />
          </div>
        </div>         */}
      </Tooltip>
       { !props.isSecret && <ExpandMore ref={anchorRef}
            aria-haspopup="true"
            onClick={(event) => setOpenMenu(true)}  
            style={{color : '#999'}}
        /> }
        <Popper
          onMouseEnter={() => { setHoverMenu(true) ; console.log('entro') }} 
          onMouseLeave={(event) => { setHoverMenu(false) ; setOpenMenu(false) }}
          open={openMenu}
          anchorEl={anchorRef.current} 
          role={undefined} 
          transition style={{ backgroundColor : bgColor }} >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper  >
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                     <MenuItem onClick={() => setOpenDialog(true)}>Eliminar Mensaje</MenuItem> 
                     { props.messagetype != 'text' && props.messagetype != 'story' && !mas24horas ? <MenuItem onClick={() => downloadOrigFile()}>Descargar Original</MenuItem> : null }
                     { props.messagetype != 'text' && props.messagetype != 'story' && props.contact.Tipo_Docu && props.contact.Nro_Docu && !props.contact.isStaff ? <MenuItem onClick={() => setOpenPreSave(true)}>Guardar como Documentación del cliente</MenuItem> : null }
                     { props.messagetype != 'text' && props.messagetype != 'story' && props.contact.isStaff ? <MenuItem onClick={() => setOpenPreSave(true)}>Guardar como Documentación de cliente específico</MenuItem> : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

    </Bubble>
    </Tooltip>
  </MessageContent>
)};

export default React.memo(Message) ; 