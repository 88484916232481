import React from 'react'
import { useState, useEffect } from 'react';

const padTime = time => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
};

const format = time => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60);

    // Get the seconds left after converting minutes
    const seconds = time % 60;

    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`;
};


const AudioTimer = (props) => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
           
            setSeconds((seconds) => seconds + 1);
           
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            { seconds === 0
                ? '0:00'
                : format(seconds)
            }

        </div>
    )
}

export default React.memo(AudioTimer) ;