import React, { useState } from 'react';
import { Dialog, DialogContentText, DialogActions, DialogTitle, DialogContent, Button, TextField, IconButton, Tooltip } from "@material-ui/core";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CommentIcon from '@material-ui/icons/Announcement';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ImageIcon from '@material-ui/icons/Image';
import MicIcon from '@material-ui/icons/Mic';
import ListIcon from '@material-ui/icons/List';
import ProductosGallery from './ProductosGallery';
import LinkIcon from '@material-ui/icons/Link';
import SendIcon from "@material-ui/icons/Send";

import { relative } from 'path';
import moment from 'moment';

import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import * as contactsActions from '../../../state/contacts/actions';
import * as authSelectors from '../../../state/auth/selectors';
import * as campaignsSelector from '../../../state/channels/selectors';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AudioTimer from './AudioTimer';
import clsx from 'clsx';
import MicRecorder from "mic-recorder-to-mp3-fixed";
import Api from '../../../state/Api';
import * as appActions from '../../../state/app/actions';

const recorder = new MicRecorder({
  bitRate: 64
});


const ActionsButton = ( props ) => {
  const classes = props.classes;

  const dispatch=useDispatch();
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const campaign = useSelector(campaignsSelector.getChannel(props.campaignid));
  const [direction, setDirection] = useState('right');
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCatalogOpen, setModalCatalogOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [commentText, setCommentText] = useState('');  
  const [isOpenAdjunto, setIsOpenAdjunto] = useState(false);  
  const [ show, setShow ] = useState(props.contact);
  const [recording, setRecording] = useState(false)
  const [enviandoDatos, setEnviandoDatos] = useState('');
  const [recordedUrl, setRecordedUrl] = useState('');

  const handleDirectionChange = event => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = event => {
    setHidden(event.target.checked);
  };

  const handleOpenResponses = () => {
    props.onOpenResponses();
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };  

  const handleModalCatalogoOpen = () => {
    setModalCatalogOpen(true);
  };

  const handleModalCatalogClose = () => {
    setModalCatalogOpen(false);
    setOpen(false)
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalTevecomprasLink = () => {
    props.onSendTevecomprasLink()
  };
  
  const handleModalClose = () => {
    setModalOpen(false);
  };  

  const handleAddPdfFile = () => {
    props.onPdfFile()
  }


  const [outgoingMessage, setMessage] = useState('');

  const sendMessage = (e) => {
    dispatch(contactsActions.sendMessage(e))
    setCommentText('');
  }

  const handleNoteAdd = () => {
    sendMessage({
      contactId: props.contact.id,
      campaignid: props.contact.campaignid,
      callerid: props.contact.callerid,
      message: {
        id: '0',
        from: me.username,
        msg: commentText,
        fechaCorta: moment().format('DD/MM/YYYY'),
        outbound: true,
        readByAgent: false,
        status: 0,
        isSecret: true
      }
    });
    
    handleModalClose();   
  }

  const sendAudioFile = file => {
    const formData = new FormData();
    const fileName = 'test.mp3'
    formData.append('tmpt', file, fileName);
    //debugger
    setEnviandoDatos(true)
    Api.req.post(`prospectos/contact/${props.contactId}/media`, formData).then((response) => {
      //debugger
      let paramsAudioFile = { name: fileName, messagereference: response.data.url }
      props.onAudioSend(paramsAudioFile) 
      setEnviandoDatos(false)
    }, (error) => {
      dispatch(appActions.addToast('Ha habido un error al subir el archivo : ' + error.response.data.msg, 'error'))
      setEnviandoDatos(false)
    })

  };

  const record = () => {
    
    if (recording) {
      stopRecording()
      setRecording(false)
    } else {
      setRecording(true)
      startRecording()
    }
  }

  const startRecording = async () => {
    recorder.start().then(() => {
      // something else
    }).catch((e) => {
      console.error(e);
    });
  };

  const cancelRecording = () => {
    setRecording(false)
    recorder
      .stop()

  }

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3().then(([buffer, blob]) => {
        // do what ever you want with buffer and blob
        // Example: Create a mp3 file and play
        const file = new File(buffer, 'me-at-thevoice.mp3', {
          type: blob.type,
          lastModified: Date.now()
        });

        const player = new Audio(URL.createObjectURL(file));
        const blobURL = URL.createObjectURL(file)
        setRecordedUrl(blobURL);
        sendAudioFile(file)
        //player.play();

      }).catch((e) => {
        alert('We could not retrieve your message');
        console.log(e);
      });
  };

  const actions = [
    { campaignId: 0, icon: <CommentIcon className={classes.iconSize} />, name: 'Agregar Observaciones', actionHandler: handleModalOpen },
    { campaignId: 0, icon: <ShoppingCartIcon className={classes.iconSize} />, name: 'Agregar Link', actionHandler: handleModalCatalogoOpen },
    { campaignId: 0, icon: <LinkIcon className={classes.iconSize} />, name: 'Agregar Link Tevecompras', actionHandler: handleModalTevecomprasLink },
    
    { campaignId: 3, icon: <AttachFileIcon className={classes.iconSize} />, name: 'Archivo', actionHandler: handleNoteAdd },
    { campaignId: 3, icon: <ImageIcon className={classes.iconSize} />, name: 'Imagen', actionHandler: handleNoteAdd },
    { campaignId: 3, icon: <MicIcon className={classes.iconSize} />, name: 'Audio', actionHandler: handleNoteAdd },

    { campaignId: 99, icon: <CommentIcon className={classes.iconSize} />, name: 'Agregar Observaciones', actionHandler: handleModalOpen },
    { campaignId: 0, icon: <ListIcon className={classes.iconSize} />, name: 'Mensajes Predefinidos', actionHandler: handleOpenResponses },
  ];  

  if(props.contact.campaign_sendFile){
    actions.push({ campaignId: 0, icon: <AttachFileIcon className={classes.iconSize} />, name: 'Adjuntar', actionHandler: handleAddPdfFile })
  }


  const handleNewMsg = (msg) => {
    setOpen(false)
    props.onNewMsg(msg)
  }

  const actionsMessageDue = () => {
    if(!props.messageDue){
      return actions.filter(x => x.name !== 'Mensajes Predefinidos' && x.name !== 'Agregar Link' )
    }else{
      return actions;
    }
  }

  return (
    <div style={{ position: 'relative', margin: '0 auto', display: props.campaignid !== 13 ? 'block' : 'none' }}>
      <ProductosGallery contact={props.contact} username={me.username} dataPanel={props.dataPanel}  modalOpen={modalCatalogOpen} onNewMessage={handleNewMsg} onModalCatalogClose={handleModalCatalogClose}></ProductosGallery>
    <Dialog open={modalOpen} onClose={handleModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">AGREGAR OBSERVACIONES</DialogTitle>
        <DialogContent  style={{width: '500px'}}>
          <DialogContentText>
            Escribir comentario.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Nota"
            // label="OBSERVACIONES"
            value={commentText}
            onChange={({ target: { value } }) => setCommentText(value)}
            onKeyPress={(event, value) => event.key === 'Enter' && commentText ? handleNoteAdd() : null}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleNoteAdd} color="primary" disabled={!commentText}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>       
          {actionsMessageDue().filter(x => x.campaignId === props.campaignid || x.campaignId === 0 ).map(action => (
            <IconButton
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.actionHandler}
            >
              <Tooltip id="button-report" title={action.name} placement="top">
                {action.icon}
              </Tooltip>
            </IconButton>
          ))}
          {props.contact.campaignid !== 13 ? <>
            
            
            {recording ?
              <Button
                variant="contained"
                color="secondary"
                className={classes.sendButton}
                style={{marginTop : '5px'}}
                startIcon={<SendIcon />}
                onClick={(e) => { record() }}
              >
                <AudioTimer />
              </Button> : ''}

            {recording ?
              <IconButton
                style={{ fontSize: '16px' }}
                onClick={(e) => { cancelRecording() }}
                className={classes.sendButton}
              >
                <DeleteIcon className={classes.sendIcon} />

              </IconButton> : ''}


            {!recording && props.contact.campaign_sendAudio ? <IconButton className={clsx(classes.sendButton, {
              [classes.recording]: recording,
            })}
              onClick={(e) => { record() }}
            >
              <MicIcon className={classes.sendIcon} />

            </IconButton> : ''}
            
              <IconButton className={classes.sendButton} 
                    disabled={!props.outgoingMessage}
                    onClick={(e) => { props.onSendMessage() }}
                >
                <SendIcon  />
              </IconButton>
            </> : '' }
    </div>
  )
}

export default ActionsButton;
