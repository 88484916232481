import React,{ useState, useEffect } from 'react';
import { TextField, Card, CardActions, CardMedia, CardContent, Typography, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../../state/Api';
import axios from 'axios';
import xml2js from "xml2js";

const useStyles = makeStyles(theme => ({
    listaScrolleable: {
        width: '100%',
        height : '100%',
        position: 'relative',
        overflowY: 'hidden',
        overflowX: 'hidden',
        flex: 1
    },
    root: {
        maxWidth: 345,
        background : "#727274"
    },
    media: {
        height: 160
    },
    cardContent : {
        paddingBottom: 5
    }
}))

const ProductosGallery = (props) => {
    const classes = useStyles();
    const [xmlData, setXmlData] = useState([]);
    const [searchField, setSearchField ] = useState('')
    const [loading, setLoading ] = useState(true)
    


    let filtered = xmlData.filter( producto => 
        producto.title.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 
    )

    const handleClickCarrito = (producto) => {
        //let utms = "&utm_source=omnichannel&utm_campaign=catalog&utm_medium=vtex"
        let utm_source = props.dataPanel.utm_source ? props.dataPanel.utm_source : getUtmSourceInit();
        let utm_campaing = props.dataPanel.utm_campaign ? props.dataPanel.utm_campaign : getUtmCampaign();
        let utm_medium = props.dataPanel.utm_medium ? props.dataPanel.utm_medium : getDefaultUtmMedium();
        let utms = `&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaing}`;
        let carritoLink =  `https://www.tevecompras.com/checkout/cart/add?sc=1&sku=${producto.id}&qty=1&seller=1${utms}`;
        props.onNewMessage(carritoLink)
        props.onModalCatalogClose()
    }


    const getUtmCampaign = () => {
        let tempName;
        let username = props.username;
        if (username.includes("_")) {
            tempName = username.split("_");
        } else {
            tempName = username;
        }

        let nameCode = tempName[0].substring(0, 1) + tempName[1].substring(0, 1);

        return nameCode + props.contact.campaignid;
    }

    const getDefaultUtmMedium = () => {

        if (props.contact.campaignid == 36) {
            return 'meta-wa-ads'
        }
        if (props.contact.source == 'whatsapp' || props.contact.source == 'whatsapp-sandbox') {
            return 'Whatsapp';
        }
        if (props.contact.source == 'chat') {
            return 'Chat';
        }
        if (props.contact.source == 'facebook') {
            return 'Facebook';
        }

        return '';

    }

    const getUtmSourceInit = () => {
        if (props.contact.campaignid == 36) {
            return 'omni-mkt'
        }
        return 'omnichannel';
    }

    const removeParams = (producto) => {
        if(props.dataPanel){
            const urlObj = new URL(producto.producturl);
            urlObj.search = '';
            const result = urlObj.toString();
            let utm_source = props.dataPanel.utm_source ? props.dataPanel.utm_source : 'omnichannel';
            let utm_campaign = props.dataPanel.utm_campaign ? props.dataPanel.utm_campaign : 'catalog';
            let utm_medium = props.dataPanel.utm_medium ? props.dataPanel.utm_medium : 'catalog';
            let carritoLink = `${result}?idsku=${producto.id}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
            return carritoLink;
        }else{
            
            return `${producto.link}&utm_source=${getUtmSourceInit()}&utm_campaign=${getUtmCampaign()}&utm_medium=${getDefaultUtmMedium() }`;
        }
        
    }

    const handleClickTvc = (producto) => {
        props.onNewMessage(removeParams(producto))
        props.onModalCatalogClose()
    }

    useEffect(
        () =>{
            if(props.modalOpen){
                const parser = new xml2js.Parser({explicitArray : false});
                Api.req.get(`utils/catalog/whatsapp.xml`).then(
                    (response) => {

                        //console.log(response)
                        parser.parseString(response.data, function (err, result) {
                            setXmlData(result.feed.product);
                            setLoading(false)
                        });
                    }
                )
                setSearchField('')
            }
    },[props.modalOpen])

    const productos = filtered.map( (producto, index) => {
        return  <Grid xs={4} item key={index}>
                    <Card className={classes.root}>
                            <CardMedia
                            className={classes.media}

                            image={producto['g:image_link']}
                            title={producto.title}
                            />
                            <CardContent className={classes.cardContent}>
                            <Typography variant="body2" >
                                {producto.title}
                            </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => handleClickCarrito(producto)} aria-label="delete">
                                    <AddShoppingCartIcon/>Carrito
                                </Button>
                                <Button onClick={() => handleClickTvc(producto)}>
                                    <InsertLinkIcon/> Link
                                </Button>
                            </CardActions>
                    </Card>
                </Grid>

    })


    return (
        <>
            <Dialog fullWidth={true} maxWidth={"md"} open={props.modalOpen} onClose={props.onModalCatalogClose}>
                <DialogTitle>Galeria De Productos    <TextField  onChange={(event)=> setSearchField(event.target.value)} style={{ float : 'right'}} id="outlined-basic" label="Busqueda" variant="outlined" align="right" /> </DialogTitle>
                <DialogContent>
                    <Grid container className={classes.listaScrolleable} spacing={2}>
                        { loading == false ? productos :  <CircularProgress color="secondary" /> }
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button onClick={props.onModalCatalogClose} color="primary">
                    Cerrar
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ProductosGallery;